<template>
  <iframe ref="iframe" class="policy"></iframe>
</template>

<script>
import { getPolicy } from '@/api/response'

export default {
  name: 'Policy',
  props: {
    lang: {
      type: String
    }
  },
  data () {
    return {
      policy: ''
    }
  },
  methods: {
    async getPolicy () {
      const { data: { success, data } } = await getPolicy(this.lang)
      if (success) this.policy = data
    },
  },
  async mounted () {
    await this.getPolicy()
    const frame = this.$refs.iframe
    frame.contentDocument.documentElement.innerHTML = this.policy
    frame.contentDocument.documentElement.style['overflow-x'] = 'hidden'
    frame.contentWindow.confirmAgreement = () => {
      this.$emit('confirmed')
    }
  },
}
</script>

<style scoped>
.policy {
  z-index: 10;
  width: 100vw;
  height: 100vh;
}
</style>
